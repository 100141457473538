import React from "react"
import styled from 'styled-components'
import {Col, Container, Row} from "react-grid-system";
import { graphql } from 'gatsby'
import Link from "gatsby-link";
import useConfig from "../hooks/use-config";
import SearchField from "../components/Search/SearchField";
import HomepageTeaser from "../components/HomepageTeaser";
import teaserHowtoGuidesBackground from '../images/CoreMedia_Icons_Layers_blue_green.svg'
import teaserHowtoGuidesBackgroundHover from '../images/CoreMedia_Icons_Layers_red_magenta.svg'
import teaserReferenceBackground from '../images/CoreMedia_Icons_Solution_purple_magenta.svg'
import teaserReferenceBackgroundHover from '../images/CoreMedia_Icons_Solution_red_magenta.svg'
import teaserDiscoverBackground from '../images/CoreMedia_Icons_Global_purple_blue.svg'
import teaserDiscoverBackgroundHover from '../images/CoreMedia_Icons_Global_red_magenta.svg'
import { GatsbyImage } from "gatsby-plugin-image"
import buttonNormal from '../images/Pfeil.svg';

const Style = styled.div`

  @media only screen and (min-width: 1201px) {
    li:after {
      content: "";
      background-image: url(${buttonNormal});
      background-size: 28px; /* Icon size */
      width: 28px; /* Icon size */
      height: 28px; /* Icon size */
      position: absolute;
      //right: 15px; /* Adjust distance from the right side */
      top: 50px; /* Vertically center the icon */
      margin-left: 30px;
      //  transform: translateY(-50%); /* Vertically center the icon */

    }
  }


  .teaser {
  margin-right: 200px
  }

  .vertical-align {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hero {
    min-height: 50vh;
    margin: -100px -15px 48px;
    position: relative;
    display: flex;
    overflow: hidden;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #868686;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      > div {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%
      }
    }

    h1 {
      font-family: "DM Sans", sans-serif;
      font-size: 4rem;
      color: #ffffff;
      line-height: 1.1;
    }

    .copy {
      font-family: "DM Sans", sans-serif;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 24px 0;

      color: #fff;

      > div {
        font-size: 2rem;
        line-height: 130%;
        flex-grow: 1;
        width: 100%;
      }
    }

      ul {
        font-size: 16px;
        list-style-type: none;
        display: flex;
        align-items: center;
        margin: 40px 0 0 0;
        line-height: 100%;

        @media only screen and (max-width: 796px) {
          flex-wrap: wrap;
        }

        li {
          padding: 16px 50px;
          border: solid 1px #fff;
          border-radius: 100px;
          margin: 0 21px 21px 0;
          padding-left: 25px;
          padding-right: 70px;

          &.primary {
            background-color: rgba(255, 255, 255, 0.8);
            border: solid 1px transparent;

            a {
              color: #3f3f3f;
            }
          }

          &:last-child {
            overflow-wrap: anywhere;
          }
        }

      }

    @media only screen and (max-width: 1200px) {
      ul > a > li {
        padding-right: 25px;
      }
    }

    .row + .row {
      margin-top: 80px
    }
  }

  @media only screen and (max-width: 992px) {
    .hero {
      margin: -100px -15px 0px;

      h1 {
        font-size: 40px;
        color: #ffffff;
      }

      .copy > div {
        font-size: 26px;
      }
    }
  }

  .card > div {
    padding: 24px;
    background-color: #fff;
    border: solid 1px #d2d2d2;
    border-radius: 12px;

    & + .card {
      margin-left: 12px;
    }
  }

  .card + .card > div {
    margin-left: 12px;
  }

  .vertical-align {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .search-bar {
    margin-bottom: 100px;
  }

  .version-selector {
    margin-top: 12px;

    label {
      margin-right: 1rem;
    }

    select {
      background-color: transparent;
      border: 1px none;
      border-bottom-style: solid;
      padding: 0 3px 3px;
    }
  }

`;

const IndexPage = ({ data }) => {

  const config = useConfig()

  return (
    <Style>
      <div className={'hero'}>
        <div className={'background'}>
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt={'Blurred view on a computer screen'}/>
        </div>
        <div className={'copy'}>
          <Container fluid md sm xs>
            <Row xs={12}>
              <Col className={'search-bar'}>
                {config.search_enabled && (
                  <SearchField type={'home'}/>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h1>CoreMedia<br/>Experience&nbsp;Platform</h1>
                <div>
                  Find detailed documentation of our<br/>
                  software, APIs third-party integrations,<br/>
                  as well as tutorials, and guides.
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ul>
                  <Link to={'/how-to-guides/quick-start/'}><li>Quick Start</li></Link>
                  <Link to={'services/'}><li>Services</li></Link>
                  <Link to={'/how-to-guides/upgrades'}><li>Upgrades</li></Link>
                  <Link to={'/whats-new/'}><li>What's New?</li></Link>
                  <Link to={'glossary/'}><li>Glossary</li></Link>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Container className={'teaser'}>
        <Row>
          <Col lg={4}>
            <HomepageTeaser
                title={'Discover'}
                ctas={[{label: 'Discover our system', link: '/discover/'}]}
                background={teaserDiscoverBackground}
                backgroundHover={teaserDiscoverBackgroundHover}
                buttonBackground={"linear-gradient(45deg, #672779, #006CAE)"}
            >
              Get inspired by browsing through our API Catalog, learning about the CoreMedia architecture and components.
            </HomepageTeaser>
          </Col>
          <Col lg={4}>
            <HomepageTeaser
              title={'How-to'}
              ctas={[{label: 'View How-to Guides', link: '/how-to-guides/'}]}
              background={teaserHowtoGuidesBackground}
              backgroundHover={teaserHowtoGuidesBackgroundHover}
              buttonBackground={"linear-gradient(45deg, #006CAE, #2FAC66)"}
            >
              Find answers quickly. Refer to these simple
              step-by-step explanations and get things done fast.
            </HomepageTeaser>
          </Col>
          <Col lg={4}>
            <HomepageTeaser
              title={'Learn'}
              ctas={[
                { label: 'Reference Materials', link: '/reference-materials/'},
              ]}
              background={teaserReferenceBackground}
              backgroundHover={teaserReferenceBackgroundHover}
              buttonBackground={"linear-gradient(45deg, #672779, #D82EB4)"}
            >
              Detailed information about our product. Go down the rabbit hole.
            </HomepageTeaser>
          </Col>
        </Row>
        {/*   <Row>
          <Col lg={4}>
            <HomepageTeaser
                title={'Campaign Service'}
                cta={'Learn about the Campaign Service'}
                background={teaserDiscoverBackground}
                link={'/services/'}
            >
              Learn how to use the Campaign Service for your headless frontends
            </HomepageTeaser>
          </Col>
          <Col lg={4}>
            <HomepageTeaser
                title={'Event Service'}
                cta={'Learn about the Event Service'}
                background={teaserDiscoverBackground}
                link={'/services/'}
            >
              Learn how to use the Event Hub Service
            </HomepageTeaser>
          </Col>
        </Row> */}
      </Container>
    </Style>
  )
}

export default IndexPage

export const query = graphql`
    {
        file(relativePath: {eq: "backgroundBlurredHands.jpeg"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
            }
        }
    }
`
